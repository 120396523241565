<script>
export default {
	props: ['data'],
}
</script>

<template>
	<CardLayout @cardClick="$emit('optionSelected')" clickable>
		<WireTransferCardContent :message="data && data.message" />
	</CardLayout>
</template>
