<script>
import { brandsLogos } from './brands-logos'
export default {
	lang: 'shop',
	props: ['order', 'checkout'],
	data() {
		return {
			loading: false,
			dialog: false,
			options: null,
		}
	},
	computed: {
		brandsLogos() {
			return brandsLogos
		},
	},
	methods: {
		openPaymentOptionsDialog() {
			this.dialog = true
			if (this.options) return
			this.loadPaymentOptions()
		},
		async loadPaymentOptions() {
			let url = this.checkout
				? `/credit-card/checkout-payment-options/${this.checkout.hash}`
				: `/credit-card/order-payment-options/${this.order.id}`
			await this.$shopApi.get({
				url,
				loading: (v) => (this.loading = v),
				onSuccess: ({ data }) => {
					this.options = data.options
				},
				onError: (response) => {
					if (this.checkout && this.checkout.catchApiErrors(response)) return
					if (response.message?.code == 'ORDER_NOT_FOUND') {
						this.$router.push({ name: 'user.orders' })
					}
				},
			})
		},
	},
}
</script>

<template>
	<CheckoutPaymentCardContent :title="$lang('Tarjeta de Crédito / Débito')" icon="credit-card-outline">
		<div class="mt-4">
			<Button @click.stop="openPaymentOptionsDialog" color="link" small>
				<v-icon small class="mr-1">mdi-credit-card-search</v-icon>
				{{ 'VER PLANES DE PAGO' | lang }}
			</Button>
			<v-dialog v-model="dialog" max-width="600px">
				<v-card :min-height="loading ? null : 500">
					<v-card-title v-if="!loading" class="d-flex align-center">
						<div>{{ 'Planes de pago' | lang }}</div>
						<v-spacer />
						<Button icon small color="grey" @click="dialog = false"><v-icon>mdi-close</v-icon></Button>
					</v-card-title>
					<v-card-text>
						<CreditCardPlanSelector
							:currency-sign="order.currency.sign"
							:options="options"
							:loading="loading"
							:selectable="false"
						/>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</CheckoutPaymentCardContent>
</template>
