<script>
export default {
	props: {
		order: Object,
	},
	data() {
		return {
			loading: false,
			inputs: {},
			action: null,
		}
	},
	methods: {
		async payOrder() {
			this.loading = true
			this.$shopApi.get(`/order/${this.order.id}/resolve-payment`, {
				onSuccess: ({ data }) => {
					this.inputs = data.inputs
					this.action = data.action
					this.$nextTick(() => {
						this.$refs.formElement.submit()
					})
				},
				onError: () => {
					this.loading = false
				},
			})
		},
	},
}
</script>

<template>
	<div>
		<div class="font-2">
			<b>Si aún no realizaste el pago</b> o querés volver a intentarlo,
			<b>hacé click en el siguiente botón</b> y serás redirigido a la pantalla de pago.
		</div>
		<div class="py-5">
			<Button color="success" :loading="loading" @click="payOrder"> Realizar pago </Button>
			<form :action="action" method="post" ref="formElement">
				<input v-for="(value, key) in inputs" :key="key" :value="value" :name="key" type="hidden" />
			</form>
		</div>
		<div class="font-1">
			Te solicitamos que <b>realices el pago dentro de las 72 hs.</b> después de la confirmación tu compra.
			Luego de ese plazo tu orden será cancelada.
		</div>
	</div>
</template>
