<script>
export default {
	name: 'PayUCardContent',
}
</script>

<template>
	<div>
		<div class="font-weight-bold">PayU</div>
	</div>
</template>
