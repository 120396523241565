<script>
export default {
	props: ['message'],
}
</script>

<template>
	<CheckoutPaymentCardContent icon="bank" title="Depósito o Transferencia bancaria">
		<v-list v-if="message">
			<v-list-item class="pl-1" dense>
				<v-list-item-icon class="mr-0">
					<v-icon small>mdi-chevron-right</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="pa-0">
					{{ message }}
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</CheckoutPaymentCardContent>
</template>
