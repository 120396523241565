import visa from './logo_visa.svg'
import visaDebit from './logo_visadebito.png'
import mastercard from './logo_master.svg'
import maestro from './logo_maestro.png'
import amex from './logo_amex.svg'

export const brandsLogos = {
	visa,
	'visa-debit': visaDebit,
	mastercard,
	maestro,
	amex,
}
