<script>
export default {
	inject: ['xcheckout'],
	data() {
		return {
			loading: false,
			inputs: {},
			action: null,
		}
	},
	computed: {
		checkout() {
			return this.xcheckout()
		},
	},
	methods: {
		async submitForm() {
			this.loading = true
			let { success, data } = await this.checkout.stepSubmit()
			if (success) {
				this.inputs = data.stepResponse.inputs
				this.action = data.stepResponse.action
				this.$nextTick(() => {
					this.$refs.formElement.submit()
				})
			} else {
				this.loading = false
			}
		},
	},
}
</script>
<template>
	<div>
		<Button @click="submitForm()" color="success" x-large block :loading="loading">
			Pagar con Pay-U
		</Button>
		<form :action="action" method="post" ref="formElement">
			<input v-for="(value, key) in inputs" :key="key" :value="value" :name="key" type="hidden" />
		</form>
	</div>
</template>
<style scoped>
form {
	display: none;
}
</style>
